.modal-title {
    margin: 1.625rem auto 0;
}

.circle {
    border-radius: 50%;
    width: 100px;
    height: 100px; 
    margin: auto;
    background-color: #16c3ad;
    color: #fff
  }

  .circle div {
    padding-top: 25px;
  }