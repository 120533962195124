#calculator-popover-btn {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.calculator-popover .popover {
  font-family: "Montserrat", sans-serif !important;
  background-color: #162039 !important;
  // will-change: transform;
  // transform: translate3d(38px, 474px, 555px);
  position: fixed !important;
  top: unset !important;
  bottom: 555px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.147427);
  max-width: 100%;
  left: 1.35rem !important;
  right: 1.35rem !important;
  transform: translate3d(0, 466px, 0px) !important;
  svg {
    pointer-events: none;
  }
  .form-group {
    label {
      font-size: 0.75rem;
      font-weight: 300;
    }
  }
}
.custom-popover {
  .popover {
    left: unset !important;
    right: unset !important;
    bottom: 523px;
  }
}

.rotate-arrow-down {
  svg {
    transition: 0.5s;
    transform: rotate(180deg);
  }
}
.rotate-arrow-up {
  svg {
    transition: 0.5s;
  }
}

.padding-top-15{
  padding-top: 15px;
}