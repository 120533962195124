// .custom-chart {
//     z-index: 1;
//     transform: scale(2.1);
//     max-width: 160px;
//     margin-left: 45px;
//   }

//   @media screen and (max-width: 764px) {
//     .custom-chart {
//       margin-left: 0px;
//     }
//   }

//   @media screen and (max-width: 360px) {
//   .custom-chart {
//     margin-left: -10px;

//   }}

.custom-chart {
  z-index: 1;
  max-width: 300px;
  margin-left: -42px;
}

@media screen and (min-width: 992px) {
  .custom-chart {
    margin-left: 30px;
  }
}
@media screen and (max-width: 764px) {
  .custom-chart {
    width: 270px;
  }
}
@media screen and (max-width: 500px) {
  .custom-chart {
    width: 240px;
  }
}

@media screen and (max-width: 400px) {
  .custom-chart {
    width: 215px;
  }
}

@media screen and (max-width: 360px) {
  .custom-chart {
    width: 200px;
  }
}
