main.welcome {
  background-color: #151f3d;
  z-index: 200;
  position: relative;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.calc-bottom-menu {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.react-notification-root {
  z-index: 9999;
  position: relative;
}

a.btn-option,
button.btn-option {
  border: 1px solid #c5cad733;
}

a.btn-option--active,
button.btn-option--active {
  border-color: #16c3ad;
}

.arrow-in-title{
  padding-left: 15px; 
}

.text-green{
  color: #16C3AD;
}

@media screen and (max-width: 380px) {
  .term-value{
    font-size: 14px;
  }

  .form-control{
    padding: 0 5px;
  }
  .form-group label{
    font-size: 15px;
  }
}
